<template>
    <div class="root-wrap">
        <svg t="1692989953165" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4696" xmlns:xlink="http://www.w3.org/1999/xlink" width="128" height="128">
            <path class="p" d="M523.395538 389.076366c-53.497425 0-97.019661-43.522236-97.019661-97.019661s43.522236-97.019661 97.019661-97.019661c53.497425 0 97.020684 43.522236 97.020684 97.019661S576.892963 389.076366 523.395538 389.076366zM523.395538 228.792846c-34.883485 0-63.263859 28.380374-63.263859 63.263859s28.380374 63.263859 63.263859 63.263859c34.884508 0 63.264882-28.380374 63.264882-63.263859S558.280046 228.792846 523.395538 228.792846z" fill="transparent" p-id="4697"></path>
            <path  class="p" d="M523.395538 790.982946c-53.497425 0-97.019661-43.522236-97.019661-97.019661 0-53.497425 43.522236-97.020684 97.019661-97.020684 53.497425 0 97.020684 43.522236 97.020684 97.020684C620.415198 747.46071 576.892963 790.982946 523.395538 790.982946zM523.395538 630.698402c-34.883485 0-63.263859 28.380374-63.263859 63.264882s28.380374 63.263859 63.263859 63.263859c34.884508 0 63.264882-28.379351 63.264882-63.263859S558.280046 630.698402 523.395538 630.698402z" fill="transparent" p-id="4698"></path>
            <path class="p" d="M336.022033 595.59286c-53.497425 0-97.020684-43.522236-97.020684-97.020684 0-53.497425 43.523259-97.019661 97.020684-97.019661s97.020684 43.522236 97.020684 97.019661C433.041694 552.070624 389.519458 595.59286 336.022033 595.59286zM336.022033 435.30934c-34.883485 0-63.264882 28.380374-63.264882 63.263859 0 34.884508 28.380374 63.264882 63.264882 63.264882 34.884508 0 63.264882-28.380374 63.264882-63.264882C399.286915 463.689714 370.906541 435.30934 336.022033 435.30934z" fill="transparent" p-id="4699"></path>
            <path class="p" d="M710.334137 595.59286c-53.497425 0-97.020684-43.522236-97.020684-97.020684 0-53.497425 43.522236-97.019661 97.020684-97.019661 53.496401 0 97.019661 43.522236 97.019661 97.019661C807.353798 552.070624 763.831562 595.59286 710.334137 595.59286zM710.334137 435.30934c-34.884508 0-63.264882 28.380374-63.264882 63.263859 0 34.884508 28.380374 63.264882 63.264882 63.264882s63.263859-28.380374 63.263859-63.264882C773.59902 463.689714 745.218646 435.30934 710.334137 435.30934z" fill="transparent" p-id="4700"></path>
            <path class="p" d="M899.214972 885.21205 521.451256 885.21205c-52.628638 0-103.696734-10.31288-151.783938-30.652114-46.434565-19.640316-88.132246-47.75156-123.93466-83.553974-35.802414-35.801391-63.913659-77.500095-83.553974-123.93466-20.339234-48.087205-30.652114-99.154277-30.652114-151.783938 0-52.628638 10.31288-103.696734 30.652114-151.783938 19.640316-46.434565 47.752584-88.132246 83.553974-123.93466 35.802414-35.802414 77.500095-63.913659 123.93466-83.553974 48.087205-20.339234 99.155301-30.652114 151.784962-30.652114s103.696734 10.31288 151.783938 30.652114c46.435589 19.640316 88.13327 47.752584 123.93466 83.553974 35.802414 35.802414 63.914682 77.500095 83.553974 123.93466 20.339234 48.087205 30.652114 99.155301 30.652114 151.783938 0 56.389288-11.782348 110.798479-35.018561 161.717172-22.444175 49.180096-54.310953 92.483344-94.717222 128.705314-9.993608 8.956999-25.354458 8.118911-34.31248-1.87265-8.958022-9.992585-8.119935-25.354458 1.87265-34.31248 35.391045-31.727609 63.297628-69.644173 82.945107-112.696712 20.326954-44.540426 30.633694-92.161003 30.633694-141.540643 0-91.172489-35.503609-176.886663-99.971899-241.354953-64.46829-64.46829-150.184511-99.972922-241.355977-99.972922-91.172489 0-176.887686 35.504632-241.355977 99.972922-64.46829 64.46829-99.971899 150.182464-99.971899 241.354953s35.504632 176.886663 99.972922 241.354953 150.183488 99.972922 241.354953 99.972922l377.762693 0c13.419637 0 24.298406 10.878768 24.298406 24.298406S912.63461 885.21205 899.214972 885.21205z" fill="transparent" p-id="4701"></path>
        </svg>
        <!-- <img ref="img" :src="require('@/assets/images/seat/1.png')" style="display:none;"/>
        <canvas id="cvs" style="width:500px;height: 500px;border:1px solid red;"></canvas> -->
    </div>
</template>
<script>
export default {
    data() {
        return {
            cvs: null,
        }
    },
    mounted() {
        const paths = document.querySelectorAll('.icon .p');
        paths.forEach((path) => {
            const len = path.getTotalLength();
            path.style.setProperty('--l', len);
        })
        // this.cvs = document.getElementById("cvs");
        // let _this = this;

        // this.$refs.img.onload = function(){
        //     let ctx = _this.cvs.getContext("2d");
        //     ctx.drawImage(this, 10,10, 5,5);
        //     ctx.drawImage(this, 20,10, 5,5);
        //     ctx.drawImage(this, 30,10, 5,5);
        // }
    },
    methods: {
    }

}
</script>
<style lang="scss">
.p {
	stroke: #f40;
	stroke-width: 20;
	stroke-dasharray: var(--l);
	stroke-dashoffset: var(--l);
	animation: stroke 2s forwards;
}

@keyframes stroke {
	to {
		stroke-dashoffset: 0;
	}
}
</style>
